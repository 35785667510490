'use client'

import { createTheme } from '@mui/material/styles'


export const theme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
            contrastText: '#000000'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    textTransform: 'none'
                },
            },
        },
    },
})